import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import styled from "@emotion/styled";

function PlusIcon() {
  return <StyledIcon icon={faPlus} color="white" />;
}

const StyledIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  padding: 5px;
  left: 0;
  background: ${({ theme }) => theme.palette.primary.main};
`;

export default PlusIcon;
