import React from "react";
import { Grid, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import InterestedItems from "../../components/InterestedItems";
import digitalisationImage from "../../images/esg/digitalisation.png";
import environmentImage from "../../images/esg/environment.png";
import ethicsImage from "../../images/esg/ethics.png";
import responsibleImage from "../../images/esg/responsible.png";
import socialImage from "../../images/esg/social.png";
import talentImage from "../../images/esg/talent.png";
import ImageCardContent from "../../components/ImageCardContent";
import { headers } from "../../utils/navbar";

const ESGDriversContent = () => [
  {
    title: <Trans>Ethics and Governance</Trans>,
    description: (
      <Trans>
        “We act with integrity and transparency, complying with the highest
        legal standards and applicable regulations at all levels of the
        organisation.”
      </Trans>
    ),
    image: ethicsImage,
    content: (
      <>
        <p>
          <Trans>
            Our principles of conduct, good corporate governance and business
            ethics are the cornerstones of our activities.
          </Trans>
          <br />
          <Trans>
            The General Code of Conduct is the key tool that we use to set out
            the principles and commitments guiding the behaviour of our
            organisation within the market and towards our stakeholders.
          </Trans>
        </p>
        <p>
          <Trans>
            Our corporate governance framework integrates all applicable
            regulations and best standards. It is aimed at ensuring excellence,
            soundness and good sense in business management, guaranteeing
            sustainability and delivering value in the long term.
          </Trans>
          <br />
          <Trans>
            Our governing bodies are responsible for overseeing and fostering
            strong regulatory relationships at all levels within the
            organisation and all business areas.
          </Trans>
          <br />
          <Trans>
            Additionally, we have established a Regulatory Compliance Control
            System aimed at guaranteeing compliance with regulations and
            internal policies.
          </Trans>
          <br />
          <Trans>
            The Internal Audit department provides the Board of Directors and
            Senior Management with a reliable and independent assessment of the
            effectiveness of the controls. This is designed to mitigate the
            significant risks that could potentially affect our business.
          </Trans>
        </p>
        <p>
          <Trans>
            The Risk Management System helps to identify, measure, control,
            mitigate and report Allfunds' financial and non-financial risks,
            including legal and regulatory risks.
          </Trans>
          <br />
          <Trans>
            Our tax strategy is aligned with the principles of integrity,
            transparency and prudence, and encourages a relationship with tax
            authorities based on trust, good faith, professionalism,
            collaboration, loyalty and reciprocity.
          </Trans>
        </p>
      </>
    ),
  },
  {
    title: <Trans>Talent Management</Trans>,
    image: talentImage,
    description: (
      <Trans>
        “We seek the personal and professional development of our employees in a
        diverse and safe working environment”
      </Trans>
    ),
    content: (
      <>
        <p>
          <Trans>Human capital is at the heart of Allfunds’ strategy.</Trans>
        </p>
        <p>
          <Trans>
            Employees are the ones who drive the success of the company through
            a culture focused on effort, continuous improvement, goal
            achievement and client service. This culture is based on solid
            corporate values such as excellence, accountability, empowerment and
            inspiration.
          </Trans>
        </p>
        <p>
          <Trans>
            Overcoming business challenges in a context of strong internal
            growth and geographic expansion means giving maximum importance to
            the ability to attract and retain the best talent.
          </Trans>
        </p>
        <p>
          <Trans>
            Accordingly, we have implemented processes to incorporate, develop
            and provide our employees with fair and attractive working
            conditions, in an environment that promotes equal opportunities,
            health and work-life balance.
          </Trans>
        </p>
        <p>
          <Trans>
            Given the nature of our activity, our employees must be constantly
            learning to be prepared for the new needs of the business.
          </Trans>
        </p>
        <p>
          <Trans>
            We have established a Training and Development Policy, which
            includes actions and measures aimed at delivering added value to
            employees and guaranteeing the highest levels of technical expertise
            and employability. We work on three main areas:
          </Trans>
          <ul>
            <li>
              <Trans>
                The development of each individual to leverage and expand their
                abilities and roles
              </Trans>
            </li>
            <li>
              <Trans>
                The creation of opportunities for growth and evolution in the
                organisation
              </Trans>
            </li>
            <li>
              <Trans>
                The development of a group of talents for a succession plan that
                ensures the continuity of the company"
              </Trans>
            </li>
          </ul>
        </p>
      </>
    ),
  },
  {
    title: <Trans>Digitalisation and Innovation</Trans>,
    description: (
      <Trans>
        “We stand for innovative digital technologies to offer products and
        services to our clients that are more agile, efficient and secure.”
      </Trans>
    ),
    image: digitalisationImage,
    content: (
      <>
        <p>
          <Trans>
            At Allfunds we are at the forefront of innovation in the WealthTech
            field. We incorporate the latest technology trends in the market to
            improve our services and increase our adaptability.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            One of our main goals is to become a 100% digital platform where
            clients, both asset managers and distributors, interact directly on
            a digital basis through the integrated Connect platform.
          </Trans>
          <br />
          <Trans>
            Since our beginning, operating as a purely European platform, we
            have continually expanded our global presence, to become a benchmark
            player worldwide. We will continue our expansion in the coming years
            by entering new markets and confirming key areas such as the United
            States and Asia, either with physical and/or digital presence.
          </Trans>
          <br />
          <Trans>
            We are already using big data to generate real-time insights into
            customer behaviour, investors and inclination. The combination of
            historical and decision-making data from the Connect platform
            generates valuable information that enables us to develop an
            advanced predictive investment behaviour model.
          </Trans>
        </p>
        <p>
          <Trans>
            We have established strategic alliances with third-party suppliers
            to incorporate new services and solutions to Allfunds Connect as
            part of a market offering that combines proprietary in-house
            applications and best-of-breed third-party tools.
          </Trans>
        </p>
        <p>
          <Trans>
            We are also innovating in the application of blockchain technology
            as a solution in complex investment fund information exchange
            processes, as it generates significant benefits for companies and
            end investors.
          </Trans>
        </p>
      </>
    ),
  },
  {
    title: <Trans>Responsible Investment</Trans>,
    image: responsibleImage,
    description: (
      <Trans>
        “We promote and integrate ESG criteria into investment services to
        encourage more sustainable capital markets.”
      </Trans>
    ),
    content: (
      <>
        <p>
          <Trans>
            At Allfunds we are committed to Socially Responsible Investment,
            which is why we adhere to the United Nations Principles for
            Responsible Investment (PRI).
          </Trans>
          <br />
          <Trans>
            ESG information is taking on an increasingly active and visible role
            within our entire offer in line with our business development
            strategy.
          </Trans>
        </p>
        <ul>
          <li>
            <Trans>
              Tailor-made fund search services: a comprehensive solution for
              fund analysis, offering our clients a variety of services; from
              the filtering of funds for the tailored universe, through the
              control and monitoring of the selected fund listings, to a
              thorough analysis of the components selected for the portfolio.
              ESG criteria have been included and/or improved in this service
              for our clients, to obtain a better aligned and more relevant fund
              selection based on these standards.
            </Trans>
          </li>
          <li>
            <Trans>
              Model Portfolios (AllPortfolios): this service offers clients the
              possibility of benefiting from our exclusive, impartial and
              independent fund selection expertise, to use the different model
              portfolios or create tailor-made portfolios according to their
              investment needs, considering the clients´ ESG criteria and, if no
              previous implementation has been carried out, to create portfolios
              whose results include ESG characteristics.
            </Trans>
          </li>
        </ul>
        <p>
          <Trans>
            We have a team of investment consultants dedicated to analysing
            funds against ESG criteria.
          </Trans>
        </p>
        <p>
          <Trans>
            We partner with companies that provide fund ESG classifications,
            ratings and impact information through the Connect platform.
          </Trans>
        </p>
      </>
    ),
  },
  {
    title: <Trans>Social Commitment</Trans>,
    image: socialImage,
    description: (
      <Trans>
        “We contribute to positive change in the communities where we operate”
      </Trans>
    ),
    content: (
      <>
        <Trans>
          We develop our business activity based on the strictest ethics,
          legality, respect for the environment and creation of wealth and
          employment in all areas of activity where we are present.
        </Trans>
        <br />
        <Trans>
          But beyond our activity, at Allfunds we are also involved in a variety
          of initiatives, activities and associations for the benefit of our
          sector and society.
        </Trans>
        <br />
        <Typography variant="h4" mt={2} mb={1}>
          <Trans>Solidarity</Trans>
        </Typography>
        <Trans>
          We develop our most important contribution through Allfunds Charity
          Fund.
        </Trans>
        <br />
        <Trans>
          Allfunds Charity Fund is an aid channel aimed at contributing to
          important social challenges such as quality education, health, child
          protection, the inclusion of people with disabilities and response to
          humanitarian emergencies.
        </Trans>
        <br />
        <Trans>
          It involves a firm commitment to the improvement of the local
          communities where we operate, as well as in many other places in need,
          through the generation of funds donated in accordance with the
          guidelines of the Fund and applicable regulations.
        </Trans>
        <br />
        <Trans>
          Our Charity Fund is governed by the Solidarity Fund Investment Policy,
          and we have a Charity Fund Committee that meets every two months to
          ensure that projects are carried out with voluntary donations from
          employees and the company itself, as well as from sponsors of
          Solidarity events and individuals (people working in the fund
          investment sector and in other sectors).
        </Trans>
        <br />
        <Trans>
          We also have a Solidarity Crowdfunding Platform
          (www.fondosolidarioallfunds.com) to centralise all the support
          received, specialising in fundraising and supported by the Spanish
          Solidarity foundation iHelp. Employees can also propose their ideas
          and challenges for fundraising through this platform.
        </Trans>
        <br />
        <Typography variant="h4" mt={2} mb={1}>
          <Trans>Associationism and sponsorship</Trans>
        </Typography>
        <Trans>
          We are part of and actively participate in various organisations and
          associations in our sector of activity, in order to raise awareness
          and promote our position on key aspects of our activity, as well as to
          encourage alliances with other organisations.
        </Trans>
        <br />
        <Typography variant="h4" mt={2} mb={1}>
          <Trans>Suppliers</Trans>
        </Typography>
        <Trans>
          Suppliers are a key element of our ESG strategy because we consider
          that our commitments on social, environmental, ethics and good
          governance issues should apply to the whole value and supply chain,
          with each and every one of the agents involved being jointly
          responsible for the entire process.
        </Trans>
        <br />
        <Trans>
          The Supplier Selection Procedure, and the Supplier Code of Conduct,
          are our internal tools that regulate the selection and contracting of
          our suppliers and our relationship with them.
        </Trans>
        <br />
        <Trans>
          These documents set out the guidelines and principles to be considered
          acceptable in the process of contracting new suppliers for any type of
          product or service, taking into account the supplier's alignment with
          our values and ethical principles in terms of good governance and ESG
          matters.
        </Trans>
      </>
    ),
  },
  {
    title: <Trans>Environmental Protection</Trans>,
    description: (
      <Trans>
        "We work towards a more efficient and environmentally friendly way of
        doing business.”
      </Trans>
    ),
    image: environmentImage,
    content: (
      <>
        <p>
          <Trans>
            We recognise the current need for and importance of protecting the
            planet. At Allfunds we assume the challenge and responsibility of
            operating in the most respectful way with the environment,
            implementing the necessary measures to mitigate our direct and
            indirect environmental impact, based on the principle of precaution.
          </Trans>
        </p>
        <p>
          <Trans>
            To do this, we analyse and manage the main environmental aspects in
            an integral way, both directly, when considering the buildings in
            which we work and indirectly, when considering those resulting from
            our activity.
          </Trans>
          <br />
          <Trans>
            We have established a Climate Change and Environmental Management
            Policy and our environmental management system is certified to ISO
            14001 standards.
          </Trans>
        </p>
        <p>
          <Trans>
            The Global Facilities Area and the Internal ESG Committee ensure
            compliance with the principles that govern this policy and guarantee
            Allfunds’ commitment to protecting the environment.
          </Trans>
        </p>
        <p>
          <Trans>Our main environmental lines of action are as follows:</Trans>
          <br />
          a)
          <Trans>Reducing the carbon footprint-climate change</Trans>
          <br />
          b)
          <Trans>Waste prevention and management</Trans>
          <br />
          c)
          <Trans>Sustainable use of resources</Trans>
          <br />
          d)
          <Trans>Environmental training and awareness campaigns</Trans>
        </p>
      </>
    ),
  },
];

function StrategicPillarsPage() {
  const interestedItems = [
    { ..._.get(headers(), "esg.elements.strategic_pillars") },
    { ..._.get(headers(), "esg.elements.policies_statements") },
    { ..._.get(headers(), "esg.elements.reports") },
  ];
  return (
    <NewLayout byDefault apps="esg.strategic_pillars">
      <Container pb={8}>
        <Typography mt={3} mb={2} fontSize={48} variant="h2">
          <Trans>Our ESG Pillars</Trans>
        </Typography>
        {/* <Typography mb={2} variant="h3">
          <Trans>Our ESG drivers and strategic pillars</Trans>
        </Typography> */}
        <Grid container>
          {ESGDriversContent().map(({
            title, description, image, content,
          }) => (
            <Grid item xs={12} sm={4} sx={{}}>
              <ImageCardContent title={title} description={description} image={image} content={content} />
            </Grid>
          ))}
        </Grid>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

export default StrategicPillarsPage;
