import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Modal from "./Modal";
import PlusIcon from "./PlusIcon";

function ImageCardContent({
  title, description, image, content,
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
        }}
      >
        <StyledImage padding={4} image={image} onClick={() => setOpen(true)}>
          <Box sx={{ position: "relative", height: "100%" }}>
            <Typography variant="h3" color="white">
              {title}
            </Typography>
            <Typography variant="small" color="white">{description}</Typography>
            <PlusIcon />
          </Box>
        </StyledImage>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <StyledBox>
          <Typography mb={3} variant="h3">
            {title}
          </Typography>
          <Box className="content">
            {content}
          </Box>
        </StyledBox>
      </Modal>
    </>
  );
}

const StyledImage = styled(Box)`
  cursor: pointer;
  height: 400px;
  display: block;
  background-size: cover !important;
  background-position: center !important;
  transition: all 0.5s;
  background: ${({ image }) => `linear-gradient(0deg, rgba(23, 48, 83, 8e-05) 0%, rgba(18, 35, 61, 0.24) 31.77%, rgba(18, 38, 66, 0.68) 63.02%), url(${image})`};
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledBox = styled(Box)`
  p, li, h3 {
    color: white;
  }
  ul {
    list-style: none;
  }
  li::before {
    color: ${({ theme }) => theme.palette.secondary.main};
    content: "•";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  * li, * p {
    font-size: 16px;
  }
  // ul[color="white"] {
  //   li::before {
  //     color: ${({ theme }) => theme.palette.inherit.main};
  //   }
  // }
`;

export default ImageCardContent;
